import React, { useCallback } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';



export const EaiaLocationForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ justifyContent: 'center' }}
        >
          <Grid item xs={12} md={5}>
            <DateInput
              label='Data Inici'
              source='start_date'
              initialValue={formType === 'edit' ? record.start_date : null}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <DateInput
              label='Data Fi'
              source='end_date'
              initialValue={formType === 'edit' ? record.end_date : null}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <ReferenceInput
              label='Vincle'
              source='bondId'
              reference='bonds'
              initialValue={formType === 'edit' ? record.bondId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'bond'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={5}>
            <ReferenceInput
              label='Provincia'
              source='stateId'
              reference='states'
              initialValue={formType === 'edit' ? record.stateId : null}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'state'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={5}>
            {values.stateId === 1 && (
              <ReferenceInput
                label='Municipi'
                source='cityId'
                reference='cities'
                initialValue={formType === 'edit' ? record.cityId : null}
                perPage={100}
                sort={{ field: 'id', order: 'ASC' }}
                validate={required()}
                filterToQuery={(searchText) => ({
                  'city[$like]': `%${searchText}%`,
                })}
              >
                <AutocompleteInput
                  optionText={'city'}
                  style={{ width: '100%' }}
                />
              </ReferenceInput>
            )}
            {values.stateId !== 1 && values.stateId !== null && (
              <ReferenceInput disabled
                label='Municipi'
                source='cityId'
                reference='cities'
                initialValue={formType === 'edit' ? record.cityId : 34}

                perPage={100}
                sort={{ field: 'id', order: 'ASC' }}
                validate={required()}
                filterToQuery={(searchText) => ({
                  'city[$like]': `%${searchText}%`,
                })}
              >
                <AutocompleteInput

                  optionText={'city'}
                  style={{ width: '100%' }}
                />
              </ReferenceInput>
            )}
            {values.cityId === 34 && values.stateId !== null && (
              <TextInput
                source='foreign_city'
                label='Indica el municipi'
                initialValue={formType === 'edit' ? record.foreign_city : null}
                validate={required()}
                style={{ width: '100%' }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source='center'
              label='Ubicació'
              initialValue={formType === 'edit' ? record.center : null}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
