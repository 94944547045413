import * as React from 'react';
import { useRef } from 'react';
import { Show, Button} from 'react-admin';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

import PrintIcon from '@mui/icons-material/Print';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  button: {
    paddingBottom: 16,
    paddingRight: 16,


  }
});

export const UserShow = (props ) => {

  const classes = useStyles();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return(
  <Show {...props}>
    <>
      <ComponentToPrint ref={componentRef}/>
      <Grid container sx={{ justifyContent: 'center' }}>
      <Button onClick={handlePrint} label="IMPRIMEIX" children={<PrintIcon />} className={classes.button}/>
      </Grid>
    </>
  </Show>
)};
