import * as React from 'react';
import { Edit, useEditController, EditContextProvider } from 'react-admin';

import { UserForm } from './UserForm';
import { FormToptoolbar } from '../common/MyTopToolbar';

export const UserEdit = (props) => {
  const controllerProps = useEditController(props);

  return (
    <EditContextProvider value={controllerProps}>
      <Edit
        {...props}
        actions={<FormToptoolbar />}
        title='Edició de Tècnic\a'
        mutationMode={'pessimistic'}
      >
        <UserForm formType='edit' formTitle={' EDITA EL PERFIL DEL TÈCNIC/A'} />
      </Edit>
    </EditContextProvider>
  );
};
