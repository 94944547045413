import polyglotI18nProvider from 'ra-i18n-polyglot';
import catalanMessages from '@joshfradley/ra-language-catalan';

catalanMessages.ra.notification.updated_password =
  "La contrasenya s'ha modificat correctament.";

catalanMessages.ra.message.bulk_delete_user =
  "Està a punt d'eliminar l'usuari %{name} |||| Està segur d'eliminar aquests %{smart_count} usuaris?";

catalanMessages.ra.message.bulk_delete_files =
  "Està a punt d'eliminar l'expedient de %{name} |||| Està segur d'eliminar aquests %{smart_count} expedients?";

export const i18nProvider = polyglotI18nProvider(() => catalanMessages, 'ca');
