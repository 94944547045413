import React, { useCallback } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,

} from 'react-admin';

import { useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaEquipForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();
  const myeaiaId = window.location.href.split('/')[5];
  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} md={6}>


            <ReferenceInput
              label="Situació Administrativa #"
              source="admin_statusId" reference="administrative-status"
              filter={{ eaiumId: myeaiaId }}
              initialValue={
                formType === 'edit' ? record.admin_statusId : null
              }
            >
              <SelectInput optionText={'id'} fullWidth
              />
            </ReferenceInput>


          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>


            <DateInput
              label='Data inici'
              source='start_date'
              initialValue={formType === 'edit' ? record.start_date : null}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>

            <DateInput
              label='Data final'
              source='end_date'
              initialValue={formType === 'edit' ? record.end_date : null}
              fullWidth
            />
          </Grid>
        </Grid>


        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={4}>
            <ReferenceInput
              label='Equip'
              source='teamId'
              reference='team'
              initialValue={formType === 'edit' ? record.teamId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
            >
              <SelectInput optionText={'name'} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={4}>




            {values.teamId !== null ? (
              values.teamId === 4 ? (
                <ReferenceInput
                  label='Equip SIFE'
                  source='sife_teamId'
                  reference='sife-team'
                  initialValue={formType === 'edit' ? record.sife_teamId : null}
                  perPage={100}
                  sort={{ field: 'id', order: 'ASC' }}
                >
                  <SelectInput optionText={'name'} fullWidth />
                </ReferenceInput>
              ) : values.teamId !== 5 ? (
                <ReferenceInput
                  label='Equip EAIA'
                  source='eaia_teamId'
                  reference='eaia-team'
                  initialValue={formType === 'edit' ? record.eaia_teamId : null}
                  perPage={100}
                  sort={{ field: 'id', order: 'ASC' }}
                  filterToQuery={(searchText) => ({
                    'name[$like]': `%${searchText}%`,
                  })}
                >
                  <AutocompleteInput optionText={'name'} fullWidth />
                </ReferenceInput>
              )
                : null) : null}
          </Grid>
          {values.teamId === 4 ? (
            values.sife_teamId === 43 ? (
              <Grid item xs={12} md={4}>
                <TextInput
                  source='another_team'
                  label='Altres'
                  initialValue={
                    formType === 'edit' ? record.another_team : null
                  }
                  fullWidth
                />
              </Grid>
            ) : null
          ) : values.eaia_teamId === 61 ? (
            <Grid item xs={12} md={4}>
              <TextInput
                source='another_team'
                label='Altres'
                initialValue={formType === 'edit' ? record.another_team : null}
                fullWidth
              />
            </Grid>
          ) : null}
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Tècnic/a 1'
              resettable={true}
              source='tecnic1Id'
              reference='users'
              initialValue={formType === 'edit' ? record.tecnic1Id : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'username'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReferenceInput
              resettable={true}
              label='Tècnic/a 2'
              source='tecnic2Id'
              reference='users'
              initialValue={formType === 'edit' ? record.tecnic2Id : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'username'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              resettable={true}
              label='Tècnic/a 3'
              source='tecnic3Id'
              reference='users'
              initialValue={formType === 'edit' ? record.tecnic3Id : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'username'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Educador/a i/o terapeuta'
              source='educatorId'
              reference='users'
              initialValue={formType === 'edit' ? record.educatorId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'username'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
