import React from 'react';
import {
  required,
  Button,
  SaveButton,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaSifeForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data inicial'
              source='start_date'
              initialValue={formType === 'edit' ? record.start_date : null}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data final'
              source='end_date'
              initialValue={formType === 'edit' ? record.end_date : null}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Municipi acollidor'
              source='cityId'
              reference='cities'
              initialValue={formType === 'edit' ? record.cityId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              filterToQuery={(searchText) => ({
                'city[$like]': `%${searchText}%`,
              })}
            >
              <AutocompleteInput optionText={'city'} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Vincle familiar'
              source='extended_bondId'
              reference='extended-bond'
              initialValue={formType === 'edit' ? record.extended_bondId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'bond'} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Composició familiar'
              source='family_compositionId'
              reference='family-composition'
              initialValue={
                formType === 'edit' ? record.family_compositionId : null
              }
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'family_composition'} fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Motiu tancament intervenció'
              source='intervention_closureId'
              reference='intervention-closure'
              initialValue={
                formType === 'edit' ? record.intervention_closureId : null
              }
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'closure_reason'} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={() => {
            return handleSubmitWithRedirect(initialValues, values);
          }}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
