import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaTerapiaEditButton } from './EaiaTerapiaEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaTerapiaCreateButton } from './EaiaTerapiaCreate';

export const EaiaTerapiaList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaTerapiaCreateButton record={props.record} onChange={handleChange} />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='terapia-familiar'
        target='eaiumId'
        perPage={10}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>
          <DateField
            source='data_open'
            label="Data inici"
            locales='es-ES'
          />
          <DateField
            source='data_close'
            label="Data fin"
            locales='es-ES'
          />
          <TextField
            label='Total de sessions'
            source='num_sessions'
          />
          <TextField
            label='Motiu del tancament'
            source='motiu_tancament'
          />
          <TextField
            label='Cumpliment dels objectius'
            source="cumpliment_objectius"
          />
          <EaiaTerapiaEditButton
            title='Edita informe'
            resource='terapia-familiar'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina terapia'
            resource='terapia-familiar'
          />
        </Datagrid>
      </ReferenceManyField >
    </>
  );
};
