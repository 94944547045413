import React, { useCallback } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaCasForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data obertura'
              source='opening_date'
              initialValue={formType === 'edit' ? record.opening_date : null}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Entrada del cas'
              source='derivationId'
              reference='derivation'
              initialValue={formType === 'edit' ? record.derivationId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
            >
              <SelectInput optionText={'name'} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Equip derivant'
              source='equipDerivantId'
              reference='equip-derivant'
              initialValue={formType === 'edit' ? record.equipDerivantId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
            >
              <SelectInput optionText={'name'} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='derivation_team'
              label='Més info'
              initialValue={formType === 'edit' ? record.derivation_team : null}

              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Municipi de referència'
              source='cityId'
              reference='cities'
              initialValue={formType === 'edit' ? record.cityId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
              filterToQuery={(searchText) => ({
                'city[$like]': `%${searchText}%`,
              })}
            >
              <AutocompleteInput optionText={'city'} fullWidth />
            </ReferenceInput>
          </Grid>
          {values.cityId === 34 && (
            <Grid item xs={12} md={6}>
              <TextInput
                source='foreign_city'
                label='Indica el municipi'
                initialValue={formType === 'edit' ? record.foreign_city : null}
                validate={required()}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
