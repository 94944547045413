import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { EaiaLocationEditButton } from './EaiaLocationEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaLocationCreateButton } from './EaiaLocationCreate';
import { datagridStyle } from '../../layout/DatagridStyle';

export const EaiaLocationList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ textDecoration: 'underline' }}>
            Històric Localitzacions
          </Typography>

          <EaiaLocationCreateButton
            record={props.record}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='eaia-location'
        target='eaiumId'
        perPage={10}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>
          <DateField source='start_date' label='Data Inici' locales='es-ES' />

          <DateField source='end_date' label='Data Fi' locales='es-ES' />
          <ReferenceField
            label='Vincle'
            source='bondId'
            reference='bonds'
            link={false}
          >
            <TextField source='bond' />
          </ReferenceField>
          <ReferenceField
            label='Provincia'
            source='stateId'
            reference='states'
            link={false}
          >
            <TextField source='state' />
          </ReferenceField>
          <FunctionField
            label='Municipi'
            render={(record) =>
              record.cityId === parseInt('34', 10) ? (
                record.foreign_city
              ) : (
                <ReferenceField
                  label='Municipi'
                  source='cityId'
                  reference='cities'
                  link={false}
                >
                  <TextField source='city' />
                </ReferenceField>
              )
            }
          />
          <TextField source='center' label='Ubicació' />
          <EaiaLocationEditButton
            title='Edita la localització'
            resource='eaia-location'
          />
          <EaiaDeleteButton
            onChange={handleChange}
            title='Eliminar localització'
            resource='eaia-location'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
