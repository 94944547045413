import React, { useCallback } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaTerapiaForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data inici'
              source='data_open'
              initialValue={formType === 'edit' ? record.data_open : null}
              // validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data fin'
              source='data_close'
              initialValue={formType === 'edit' ? record.data_close : null}
              // validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={4}>
            <NumberInput
              label='Total de sessions'
              source='num_sessions'
              initialValue={formType === 'edit' ? record.num_sessions : 0}
              // validate={required()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextInput
              label='Motiu del tancament'
              source='motiu_tancament'
              initialValue={formType === 'edit' ? record.motiu_tancament : null}
              // validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>

            <SelectInput
              source="cumpliment_objectius"
              initialValue={formType === 'edit' ? record.cumpliment_objectius : null}
              choices={[
                { id: 'Sí', name: 'Sí' },
                { id: 'No', name: 'No' },
                { id: 'Parcialment', name: 'Parcialment' },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
