import * as React from 'react';
import { SimpleShowLayout, TextField, DateField } from 'react-admin';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <SimpleShowLayout>
        <TextField source='firstname' label='Nom' />
        <TextField source='lastname' label='Cognoms' />
        <DateField label='Data de creació' source='createdAt' locales='es' />
        <DateField
          showTime={true}
          label="Data de l'última modificació"
          source='updatedAt'
          locales='es'
        />
      </SimpleShowLayout>
    </div>
  );
});
