import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  overrides: {
    MuiToolbar: {
      // override the styles of all instances of this component
      dense: {
        // Name of the rule
        minHeight: 60, // Some CSS
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: '#184f7b',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.75)',
      },
    },
    RaSidebar: {
      root: {
        height: 'calc(100vh - 4em)',
      },
      fixed: {
        height: 'calc(100vh - 4em)',
      },
    },

    RaLayout: {
      appFrame: {
        marginTop: '60px !important',
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxHeight: '45vh',
      },
    },
  },
});

export default myTheme;
