import * as React from 'react';
import {
  FormWithRedirect,
  TextInput,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  required,
  email,
  SaveButton,
  DeleteButton,
  useTranslate,
  Toolbar,
} from 'react-admin';
import { UserEditPassword } from './UserEditPassword';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// TODO: Afegir paddings per mobile

const PasswordValidate = (values) => {
  const errors = {};

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Les contrasenyes no coincideixen';
  }
  return errors;
};

export const UserForm = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const translate = useTranslate();
  const deleteConfirm = translate('ra.message.bulk_delete_user', {
    name: `${props.record.firstname} ${props.record.lastname}`,
    smart_count: 1,
  });
  return (
    <FormWithRedirect
      {...props}
      warnWhenUnsavedChanges
      validate={PasswordValidate}
      redirect={props.formType === 'edit' ? false : 'list'}
      render={(formProps) => {
        return (
          <form>
            <Typography
              variant='h5'
              align={isDesktop ? 'center' : 'left'}
              sx={{ marginTop: 4, marginBottom: 4 }}
            >
              {props.formTitle}
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ justifyContent: 'center' }}
            >
              <Grid
                item
                xs={12}
                md={10}
                style={{ paddingRight: isDesktop ? 24 : 0 }}
              >
                <TextInput
                  source='username'
                  label={`Nom d'usuari/a`}
                  validate={required()}
                  style={{ width: isDesktop ? '50%' : '100%' }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ justifyContent: 'center' }}
            >
              <Grid item xs={12} md={5}>
                <TextInput
                  source='firstname'
                  label='Nom'
                  validate={required()}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextInput
                  source='lastname'
                  label='Cognoms'
                  validate={required()}
                  style={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <TextInput
                  source='email'
                  type='email'
                  label='Email'
                  validate={[email(), required()]}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextInput
                  source='phone'
                  label='Telèfon'
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ReferenceInput
                  source='specialistId'
                  reference='specialists'
                  label='Tipus de tècnic/a'
                  validate={required()}
                  sort={{ field: 'id', order: 'ASC' }}
                >
                  <SelectInput
                    optionText={'specialist'}
                    style={{ width: '100%' }}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={5}>
                <ReferenceInput
                  source={'roleId'}
                  reference={'roles'}
                  label='Rol'
                  validate={required()}
                  sort={{ field: 'id', order: 'ASC' }}
                >
                  <SelectInput optionText={'role'} style={{ width: '100%' }} />
                </ReferenceInput>
              </Grid>
              {props.formType === 'create' ? (
                <>
                  <Grid item xs={12} md={5}>
                    <PasswordInput
                      source='password'
                      label='Contrasenya'
                      validate={props.formType === 'create' && required()}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <PasswordInput
                      label='Confirma contrasenya'
                      source='confirmPassword'
                      validate={props.formType === 'create' && required()}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={11}>
                  {' '}
                  <UserEditPassword {...props} />
                </Grid>
              )}
            </Grid>
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <SaveButton
                disabled={formProps.pristine || formProps.submitting}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              {props.formType === 'edit' && (
                <DeleteButton
                  record={formProps.record}
                  mutationMode='pessimistic'
                  confirmTitle={deleteConfirm}
                />
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};
