// in src/Foo.js
import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useParams } from 'react-router-dom';

// in src/users.js

import { Title } from 'react-admin';

// TODO: CUSTOM SORTBY BUTTON TO BE ABLE TO SORT BY ASSOCIATED ENTITY PROPERTY (ROLE & SPECIALIST)

const Another = (props) => {
  let { eaiaId } = useParams();

  return (
    <Card>
      <Title title='INFORMES' />
      <CardContent>{`Este es el expediente ${eaiaId}`}</CardContent>
    </Card>
  );
};

export default Another;
