import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import { Button, useNotify, useDelete, Confirm } from 'react-admin';

import IconContentDelete from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    color: '#f44336',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.12)',
    },
  },
});

export const EaiaDeleteButton = ({ record, onChange, resource, title }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const refresh = useRefresh();
  const [deleteOne, { loading }] = useDelete(resource, record.id, record, {
    onSuccess: () => {
      // onChange to refresh datagrid component
      notify('ra.notification.deleted', {
        messageArgs: { smart_count: 1 },
      });
      onChange();
      refresh();
    },
    onFailure: ({ error }) => {
      notify(error.message, 'error');
    },
  });

  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} className={classes.button}>
        <IconContentDelete />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={title}
        content='ra.message.delete_content'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
