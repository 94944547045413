import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '../common/Logo';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 0.8,
  },
  margin: {
    marginLeft: 10,
  },
});

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to='/configuration'
    primaryText='El meu perfil'
    leftIcon={<SettingsIcon />}
    onClick={onClick} // close the menu on click
  />
));

const MyUserMenu = (props) => (
  <UserMenu {...props}>{/*  <ConfigurationMenu /> */}</UserMenu>
);

const MyAppBar = (props) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <span className={classes.margin} />
      <Logo />
      <span className={classes.spacer} />
      {isDesktop && (
        <Typography
          variant='h6'
          color='inherit'
          className={classes.title}
          id='react-admin-title'
        />
      )}
    </AppBar>
  );
};

export default MyAppBar;
