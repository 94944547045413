import React, { useCallback } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaReportForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data'
              source='data'
              initialValue={formType === 'edit' ? record.data : null}
              // validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              label="Tipus d'informe"
              source='rtypeId'
              reference='report-type'
              initialValue={formType === 'edit' ? record.rtypeId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
            // validate={required()}
            >
              <SelectInput optionText={'name'} fullWidth />
            </ReferenceInput>
          </Grid>


        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
