import * as React from 'react';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { EaiaTerapiaList } from './EaiaTerapiaList';

const useStyles = makeStyles({
  accordion: {
    border: '0.5px solid #5e738b',
  },
  accordionSummary: {
    backgroundColor: '#5e738b !important',
    color: '#ffffff !important',
    display: 'flex !important',
    padding: '0px 16px !important',
  },
  arrow: {
    fontSize: '2rem !important',
    color: '#ffffff !important',
  },
});

export const EaiaTerapia = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel5');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === 'panel5'}
      onChange={handleChange('panel5')}
      className={classes.accordion}
    >
      <AccordionSummary
        aria-controls='panel5d-content'
        id='panel2d-header'
        className={classes.accordionSummary}
        expandIcon={<ArrowDropDown className={classes.arrow} />}
      >
        <Typography variant='h6'>Teràpia familiar</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <EaiaTerapiaList {...props} />
      </AccordionDetails>
    </Accordion>
  );
};
