import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaCasEditButton } from './EaiaCasEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaCasCreateButton } from './EaiaCasCreate';

export const EaiaCasList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaCasCreateButton record={props.record} onChange={handleChange} />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='eaia-derivation'
        target='eaiumId'
        perPage={5}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>
          <DateField
            source='opening_date'
            label="Data d'obertura"
            locales='es-ES'
          />

          <ReferenceField
            label='Entrada del cas'
            source='derivationId'
            reference='derivation'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField
            label='Equip derivant'
            source='equipDerivantId'
            reference='equip-derivant'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>

          <TextField source='derivation_team' label='Més info' />

          <FunctionField
            label='Municipi'
            render={(record) =>
              record.cityId === parseInt('34', 10) ? (
                record.foreign_city
              ) : (
                <ReferenceField
                  label='Municipi referència'
                  source='cityId'
                  reference='cities'
                  link={false}
                >
                  <TextField source='city' />
                </ReferenceField>
              )
            }
          />
          <EaiaCasEditButton
            title='Edita derivació'
            resource='eaia-derivation'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina derivació'
            resource='eaia-derivation'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
