// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,

  SimpleList,

} from 'react-admin';
import { ListActions } from '../common/MyTopToolbar';
import { eaiaFilters } from './EaiaFilters';
import { useMediaQuery } from '@material-ui/core';



// TODO: CUSTOM SORTBY BUTTON TO BE ABLE TO SORT BY ASSOCIATED ENTITY PROPERTY (ROLE & SPECIALIST)

export const EaiaList = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListActions />}
      filters={eaiaFilters}
      style={{ overflow: 'auto' }}
      title="Llista d'Expedients"

    >
      {isDesktop ? (
        <Datagrid rowClick='show' >
          <TextField source='eaia_surname' label='Cognoms' />
          <TextField source='eaia_name' label='Nom' />
          <TextField source='eaia_birthdate' label='Data de naixement' />
          <TextField source='eaia_sinia' label='Codi SINIA' />
          {/*   <ReferenceManyField label="Comments by" reference="eaia-closure" target="eaiumId">
            <Datagrid>

              <TextField source="observations" />

            </Datagrid>
          </ReferenceManyField>*/}

          {/*   <ReferenceField
            source='cityId'
            reference='cities'
            label='Ciutat'
            link={false}
            sortable={false}
          >
            <TextField source='city' />
          </ReferenceField>*/}
          <EditButton />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(record) =>
            `${record.eaia_name} ${record.eaia_lastname}`
          }
        /* secondaryText={(record) => (
           <ReferenceField
             record={record}
             source='cityId'
             reference='cities'
             link={false}
           >
             <TextField source='cities' />
           </ReferenceField>
         )} */
        /* tertiaryText={(record) => (
          <ReferenceField
            record={record}
            source='roleId'
            reference='roles'
            sortBy='role'
            link={false}
          >
            <TextField source='role' />
          </ReferenceField>
        )} */
        />
      )}
    </List>
  );
};
