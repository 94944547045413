import * as React from 'react';
import { Route } from 'react-router-dom';
import Foo from './customPage';
import Another from './anotherPage';
import Informes from './informes';
import InformesFiltres from './informesfiltres';
import { EaiaEdit } from './eaias/EaiaEdit';

export const Routes = [
  <Route exact path='/informesfiltres' component={InformesFiltres} />,
  <Route exact path='/informes' component={Informes} />,
  <Route exact path='/foo' component={Foo} />,
  <Route path='/foo/informe/:eaiaId' component={Another} />,
];
