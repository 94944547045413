import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
} from 'react-admin';
import Grid from '@mui/material/Grid';

import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaSifeEditButton } from './EaiaSifeEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaSifeCreateButton } from './EaiaSifeCreate';

export const EaiaSifeList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaSifeCreateButton record={props.record} onChange={handleChange} />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='extended-family'
        target='eaiumId'
        perPage={5}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>
          <DateField source='start_date' label='Data inici' locales='es-ES' />
          <DateField source='end_date' label='Data final' locales='es-ES' />

          <ReferenceField
            label='Vincle Familiar'
            source='extended_bondId'
            reference='extended-bond'
            link={false}
          >
            <TextField source='bond' />
          </ReferenceField>

          <ReferenceField
            label='Composició Familiar'
            source='family_compositionId'
            reference='family-composition'
            link={false}
          >
            <TextField source='family_composition' />
          </ReferenceField>

          <ReferenceField
            label='Municipi nucli acollidor'
            source='cityId'
            reference='cities'
            link={false}
          >
            <TextField source='city' />
          </ReferenceField>

          <ReferenceField
            label='Motiu tancament'
            source='intervention_closureId'
            reference='intervention-closure'
            link={false}
          >
            <TextField source='closure_reason' />
          </ReferenceField>

          <EaiaSifeEditButton
            title='Edita Família'
            resource='extended-family'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina Família'
            resource='extended-family'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
