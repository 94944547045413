// LoginPage.js
import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import backgroundLogin from '../assets/background-login.jpg';
import logoConsell from '../assets/logo-consell-black.png';

const styles = {
  avatar: {
    background: `url(${logoConsell})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '175px',
    backgroundPosition: 'top',
    height: 60,
  },
  icon: { display: 'none' },
  card: {
    width: 320,
    paddingTop: '20px !important',
  },
};

const MyLoginPage = (props) => (
  <Login backgroundImage={backgroundLogin} {...props} />
);

export default withStyles(styles)(MyLoginPage);
