import * as React from 'react';
import { useState } from 'react';
import { Edit, useEditController, EditContextProvider } from 'react-admin';

import { EaiaForm } from './EaiaForm';
import { FormToptoolbar } from '../common/MyTopToolbar';

export const EaiaEdit = (props) => {
  const [title, setTitle] = useState();
  const handleSetTitle = (title) => setTitle(title);

  const controllerProps = useEditController(props);
  return (
    <EditContextProvider value={controllerProps}>
      <Edit
        {...props}
        actions={<FormToptoolbar />}
        title={`Edició d'expedient de ${title}`}
        mutationMode={'pessimistic'}
      >
        <EaiaForm formType='edit' setTitle={handleSetTitle} />
      </Edit>
    </EditContextProvider>
  );
};
