import * as React from 'react';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { EaiaSifeList } from './EaiaSifeList';

const useStyles = makeStyles({
  accordion: {
    border: '0.5px solid #4c87b9',
  },
  accordionSummary: {
    backgroundColor: '#4c87b9 !important',
    color: '#ffffff !important',
    display: 'flex !important',
    padding: '0px 16px !important',
  },
  arrow: {
    fontSize: '2rem !important',
    color: '#ffffff !important',
  },
});

export const EaiaSife = (props) => {
  const [expanded, setExpanded] = React.useState('panel2');
  const classes = useStyles();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === 'panel2'}
      onChange={handleChange('panel2')}
      className={classes.accordion}
    >
      <AccordionSummary
        aria-controls='panel2d-content'
        id='panel2d-header'
        className={classes.accordionSummary}
        expandIcon={<ArrowDropDown className={classes.arrow} />}
      >
        <Typography variant='h6'>Família extensa</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <EaiaSifeList {...props} />
      </AccordionDetails>
    </Accordion>
  );
};
