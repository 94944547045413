// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  ReferenceField,
  SimpleList,
} from 'react-admin';
import { userFilters } from './UserFilters';
import { ListActions } from '../common/MyTopToolbar';

import { useMediaQuery } from '@material-ui/core';

// TODO: CUSTOM SORTBY BUTTON TO BE ABLE TO SORT BY ASSOCIATED ENTITY PROPERTY (ROLE & SPECIALIST)

export const UserList = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <List
      {...props}
      actions={<ListActions />}
      bulkActionButtons={false}
      filters={userFilters}
      /*  style={{ overflow: 'auto' }} */
    >
      {isDesktop ? (
        <Datagrid rowClick='show'>
          <TextField source='firstname' label='Nom' />
          <TextField source='lastname' label='Cognoms' />
          <ReferenceField
            source='specialistId'
            reference='specialists'
            label='Tipus de Tècnic/a'
            link={false}
            sortable={false}
          >
            <TextField source='specialist' />
          </ReferenceField>
          <ReferenceField
            source='roleId'
            reference='roles'
            label='Rol'
            link={false}
            sortable={false}
          >
            <TextField source='role' />
          </ReferenceField>
          <EmailField source='email' />
          <EditButton />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(record) => `${record.firstname} ${record.lastname}`}
          secondaryText={(record) => (
            <ReferenceField
              record={record}
              source='specialistId'
              reference='specialists'
              link={false}
            >
              <TextField source='specialist' />
            </ReferenceField>
          )}
          tertiaryText={(record) => (
            <ReferenceField
              record={record}
              source='roleId'
              reference='roles'
              sortBy='role'
              link={false}
            >
              <TextField source='role' />
            </ReferenceField>
          )}
        />
      )}
    </List>
  );
};
