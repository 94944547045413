import * as React from 'react';
import {
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  required,
} from 'react-admin';

import FormControl from '@mui/material/FormControl';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { EaiaLocationList } from './EaiaLocationList';
import { EaiaVincleList } from '../vincle/EaiaVincleList';
const useStyles = makeStyles({
  input: {
    '& input.Mui-disabled': {
      color: 'black',
      '-webkit-text-fill-color': 'black',
    },
    '& label.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& div.MuiFilledInput-root.Mui-disabled': {
      backgroundColor: 'unset',
    },
    '&::before': {
      borderBottom: 'none !important',
    },
  },
  accordion: {
    border: '0.5px solid #d05454',
  },
  accordionSummary: {
    backgroundColor: '#d05454 !important',
    color: '#ffffff !important',
    display: 'flex !important',
    padding: '0px 16px !important',
  },
  arrow: {
    fontSize: '2rem !important',
    color: '#ffffff !important',
  },
});

export const EaiaGeneral = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      className={classes.accordion}
    >
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        className={classes.accordionSummary}
        expandIcon={<ArrowDropDown className={classes.arrow} />}
      >
        <Typography variant='h6'>Dades Generals</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="center"
          alignItems="center">
          <BooleanInput label="Cas tancat/obert" source="eaia_active" />
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ justifyContent: 'center' }}
        >
          <Grid
            item
            xs={12}
            md={5}
          >


            <TextInput
              source='eaia_sinia'
              label={`SINIA`}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
          >
            <TextInput
              source='eaia_nationality'
              label={`Nacionalitat`}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ justifyContent: 'center' }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              source='eaia_name'
              label='Nom'
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source='eaia_surname'
              label='Cognoms'
              validate={required()}
              fullWidth
            />
          </Grid>

          <Grid item xs={10} md={4}>
            <DateInput
              source='eaia_birthdate'
              label='Data de naixement'
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <FormControl
              variant='standard'
              sx={{ width: '100%' }}
              color={'secondary'}
            >
              <TextInput
                id='age'
                label='Edat'
                source='eaia_birthdate'
                format={(v) => {
                  if (v === undefined) return '-';
                  const birthDate = new Date(v);
                  const current = new Date();
                  let age = current.getFullYear() - birthDate.getFullYear();
                  const m = current.getMonth() - birthDate.getMonth();
                  if (
                    m < 0 ||
                    (m === 0 && current.getDate() < birthDate.getDate())
                  ) {
                    age--;
                  }
                  return age;
                }}
                disabled
                className={classes.input}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <ReferenceInput label='Gènere' source='genreId' reference='genre'>
              <RadioButtonGroupInput optionText='genre' />
            </ReferenceInput>
          </Grid>
          {/* 
          <Grid item xs={6} md={2.5}>
            <ReferenceInput
              source='stateId'
              reference='states'
              label='Provincia infant'
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText={'state'} style={{ width: '100%' }} />
            </ReferenceInput>
          </Grid>
         
         <Grid item xs={6} md={2.5}>
            <ReferenceInput
              source='cityId'
              reference='cities'
              perPage={100}
              label='Municipi infant'
              validate={required()}
              sort={{ field: 'id', order: 'ASC' }}
              filterToQuery={(searchText) => ({
                'city[$like]': `%${searchText}%`,
              })}
            >
              <AutocompleteInput optionText={'city'} fullWidth />
            </ReferenceInput>
          </Grid>
             
            
          <Grid item xs={12} md={5}>
            <TextInput
              source='eaia_ubication_center'
              label='Ubicació infant'
              validate={required()}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source='eaia_ubication_father'
              label='Ubicació pare'
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source='eaia_ubication_mother'
              label='Ubicació mare'
              style={{ width: '100%' }}
            />
          </Grid>*/}
        </Grid>
        {props.formType === 'edit' && <EaiaLocationList {...props} />}
        {props.formType === 'edit' && <EaiaVincleList {...props} />}
      </AccordionDetails>
    </Accordion>
  );
};
