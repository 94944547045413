export const authClientOptions = {
  storageKey: 'feathers-jwt', // The key in localStorage used to store the authentication token
  authenticate: {
    // Options included in calls to Feathers client.authenticate
    strategy: 'local',
  },
  permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
  permissionsField: 'role', // The key in the decoded JWT containing the user's role
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'username', // The key used to provide the username to Feathers client.authenticate

  logoutOnForbidden: true,
};

export const restClientOptions = {
  /* id: 'id', */
  usePatch: true,
  // In this example, the database uses '_id' rather than 'id'
  //usePatch: true, // Use PATCH instead of PUT for updates
  /* Allows to use custom query operators from various feathers-database-adapters in GET_MANY calls.
    Will be merged with the default query operators ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in']
   */
  customQueryOperators: ['$like'],
};
