import { makeStyles } from '@material-ui/core/styles';

export const datagridStyle = makeStyles({
  buttons: {
    '& td:last-child, td:nth-last-child(2)': {
      padding: 0,
      width: 64,
    },
    '&  td:nth-last-child(2)': { paddingLeft: 20 },
  },
});
