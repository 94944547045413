import { Layout } from 'react-admin';
/* import MyAppBar from './MyAppBar';
import MySidebar from './MySidebar'; */
import MyAppBar from './AppBar';
import { MyMenu } from './Menu';
/* import MyNotification from './MyNotification'; */

const MyLayout = (props) => (
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
);

export default MyLayout;
