import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import feathersClient from './client/feathersClient';
import { authClient, restClient } from 'ra-data-feathers';
import { restClientOptions, authClientOptions } from './client/clientOptions';
import { i18nProvider } from './i18n/i18nProvider';

import myTheme from './layout/MyTheme';
import MyLayout from './layout/MyLayout';

import MyLoginPage from './layout/LoginPage';
import { UserList } from './users/UserList';
import { UserCreate } from './users/UserCreate';
import { UserEdit } from './users/UserEdit';
import { UserShow } from './users/UserShow';

import { EaiaList } from './eaias/EaiaList';
import { EaiaEdit } from './eaias/EaiaEdit';
import { EaiaCreate } from './eaias/EaiaCreate';

import UserIcon from '@material-ui/icons/Group';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import { Routes } from './customRoutes';
import { Link } from 'react-router-dom';
const App = () => {
  return (
    <Admin
      layout={MyLayout}
      theme={myTheme}
      i18nProvider={i18nProvider}
      authProvider={authClient(feathersClient, authClientOptions)}
      dataProvider={restClient(feathersClient, restClientOptions)}
      loginPage={MyLoginPage}
      disableTelemetry
      customRoutes={Routes}
    >
      {(permissions) => [

        <Resource
          name='eaia'
          options={{ label: 'Expedients' }}
          list={EaiaList}
          create={EaiaCreate}
          edit={EaiaEdit}
          icon={LibraryBooks}
        />,

        permissions === 'admin' ? (
          <Resource
            name='users'
            options={{ label: 'Tècnics/ques' }}
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
            icon={UserIcon}
          />
        ) : (
          <Resource name='users' show={UserShow} />
        ),
        <Resource name='roles' />,
        <Resource name='specialists' />,
        <Resource name='cities' />,
        <Resource name='states' />,
        <Resource name='genre' />,
        <Resource name='bonds' />,
        <Resource name='equip-derivant' />,
        <Resource name='derivation' />,
        <Resource name='eaia-location' />,
        <Resource name='eaia-derivation' />,
        <Resource name='administrative-status' />,
        <Resource name='intervention-phase' />,
        <Resource name='protection-measure' />,
        <Resource name='cose-closure' />,
        <Resource name='team' />,
        <Resource name='sife-team' />,
        <Resource name='eaia-team' />,
        <Resource name='intervention-team' />,
        <Resource name='extended-bond' />,
        <Resource name='family-composition' />,
        <Resource name='intervention-closure' />,
        <Resource name='extended-family' />,
        <Resource name='case-closure' />,
        <Resource name='eaia-closure' />,
        <Resource name='eaia-bond' />,
        <Resource name='report-type' />,
        <Resource name='reports-made' />,
        <Resource name='terapia-familiar' />,


      ]}



    </Admin>
  );
};
export default App;
