import * as React from 'react';
import {
  TopToolbar,
  ListButton,
  FilterButton,
  CreateButton,
  ExportButton,

} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';


export const FormToptoolbar = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label=''
      icon={<CloseIcon />}
      style={{ color: 'grey' }}
    />
  </TopToolbar>
);

export const ListActions = () => (
  <TopToolbar>
    {/* <SortButton fields={['eaia_name', 'eaia_surname', 'roleId']} /> */}
    <FilterButton />
    {/*<ExportButton />*/}

    <CreateButton />
  </TopToolbar>
);
