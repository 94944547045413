import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  SimpleForm,
  PasswordInput,
  useUpdate,
  useNotify,
  useRefresh,
  useTranslate,
  Toolbar,
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

export const UserEditPassword = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const PasswordValidate = (values) => {
    const errors = {};

    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Les contrasenyes no coincideixen';
    }
    return errors;
  };

  const handleSave = async (values) => {
    delete values.confirmPassword;

    await update(
      { resource: 'users', payload: { id: record.id, data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          notify('ra.notification.updated_password', {
            messageArgs: { smart_count: 1 },
          });
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick}>
        <span style={{ fontSize: 14 }}>Modifica la contrasenya</span>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Modifica la contrasenya'
        style={{ maxWidth: 420, margin: 'auto' }}
      >
        <DialogTitle>Modifica la contrasenya</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <SimpleForm
            resource='users'
            save={handleSave}
            toolbar={
              <UserEditPasswordToolbar
                onLoading={loading}
                onCancel={handleCloseClick}
              />
            }
            validate={PasswordValidate}
          >
            <PasswordInput
              source='password'
              label='Contrasenya'
              validate={required()}
            />
            <PasswordInput
              source='confirmPassword'
              label='Confirma contrasenya'
              validate={required()}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

function UserEditPasswordToolbar({ onCancel, onLoading, ...props }) {
  return (
    <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
      <SaveButton disabled={onLoading} />
      <CancelButton onClick={onCancel} />
    </Toolbar>
  );
}

function CancelButton(props) {
  const translate = useTranslate();
  return (
    <Button label={translate('ra.action.cancel')} {...props}>
      <IconCancel />
    </Button>
  );
}
