import React, { useState } from 'react';
import {
  Button,
  useNotify,
  useDelete,
  Confirm,
  useRedirect,
} from 'react-admin';
import IconContentDelete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    color: '#f44336',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.12)',
    },
  },
});

export const EaiaCustomDeleteButton = ({
  record,
  handleWarn,
  confirmTitle,
  label,
}) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const classes = useStyles();

  const [deleteOne, { loading, error }] = useDelete('eaia', record.id, record, {
    onSuccess: () => {
      notify('ra.notification.deleted', {
        messageArgs: { smart_count: 1 },
      });
      // Modiffies warnWhenUnsavedChanges attribute
      handleWarn();
      redirect('/eaia');
    },
    onFailure: ({ error }) => {
      notify(error.message, 'error');
    },
  });

  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} label={label} className={classes.button}>
        <IconContentDelete style={{ color: 'red' }} />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={'ra.message.delete_content'}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
