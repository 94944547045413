import React, { useCallback } from 'react';
import { Button, SaveButton, SelectInput, useGetList } from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaVincleForm = ({
  eaia,
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { data, ids, loading, error } = useGetList(
    'eaia',
    { page: 1, perPage: 10000 },
    {
      field: 'eaia_name',
      order: 'DESC',
    }
  );
  const filteredIds = ids.filter((el) => el !== eaia.id);
  const choices = filteredIds
    .map((el) => data[el])
    .sort((a, b) =>
      a.eaia_name.toUpperCase() > b.eaia_name.toUpperCase() ? 1 : -1
    );
  const { values, initialValues } = useFormState();

  const optionRenderer = (choice) =>
    `${choice.eaia_name} ${choice.eaia_surname}`;
  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <SelectInput
              source='eaia'
              optionText={optionRenderer}
              label='Tria un/a Germà/na'
              choices={choices}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
