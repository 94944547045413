import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaEquipEditButton } from './EaiaEquipEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaEquipCreateButton } from './EaiaEquipCreate';

export const EaiaEquipList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaEquipCreateButton
            record={props.record}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='intervention-team'
        target='eaiumId'
        perPage={5}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>

          <ReferenceField
            label='Fase intervenció'
            source='admin_statusId'
            reference='administrative-status'
            link={false}
          >
            <ReferenceField
              source='phaseId'
              reference='intervention-phase'
              link={false}
            >
              <TextField source='name' />
            </ReferenceField>

          </ReferenceField>

          {/* <ReferenceField
            label='Data inici'
            source='admin_statusId'
            reference='administrative-status'
            link={false}
          >
            <DateField source='start_date' locales='es-ES' />
          </ReferenceField>
          <ReferenceField
            label='Data final'
            source='admin_statusId'
            reference='administrative-status'
            link={false}
          >
            <DateField source='end_date' locales='es-ES' />
          </ReferenceField>*/}

          <DateField source='start_date' label='Data inici' locales='es-ES' />
          <DateField source='end_date' label='Data final' locales='es-ES' />

          <ReferenceField
            label='Equip'
            source='teamId'
            reference='team'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>

          <FunctionField
            render={(record) =>
              record.teamId === parseInt('4', 10) ? (
                <ReferenceField
                  source='sife_teamId'
                  reference='sife-team'
                  link={false}
                >
                  <TextField source='name' />
                </ReferenceField>
              ) : (
                <ReferenceField
                  source='eaia_teamId'
                  reference='eaia-team'
                  link={false}
                >
                  <TextField source='name' />
                </ReferenceField>
              )
            }
          />
          <FunctionField
            render={(record) =>
              record.eaia_teamId === parseInt('61', 10) ? (
                <TextField source='another_team' />
              ) : null
            }
          />
          <FunctionField
            label='Tècnics/ques'
            render={(record) => (
              <Grid
                container
                rowSpacing={1.5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  flexDirection: 'column',
                }}
              >
                <Grid item xs={12}>
                  <ReferenceField
                    source='tecnic1Id'
                    reference='users'
                    link={false}
                  >
                    <FunctionField
                      render={(record) =>
                        `${record.username}`
                      }
                    />
                  </ReferenceField>
                </Grid>

                {record.tecnic2Id && (
                  <Grid item xs={12}>
                    <ReferenceField
                      source='tecnic2Id'
                      reference='users'
                      link={false}
                    >
                      <FunctionField
                        render={(record) =>
                          `${record.username}`
                        }
                      />
                    </ReferenceField>
                  </Grid>
                )}

                {record.tecnic3Id && (
                  <Grid item xs={12}>
                    <ReferenceField
                      source='tecnic3Id'
                      reference='users'
                      link={false}
                    >
                      <FunctionField
                        render={(record) =>
                          `${record.username}`
                        }
                      />
                    </ReferenceField>
                  </Grid>
                )}
              </Grid>
            )}
          />

          <ReferenceField
            label='Educadors/es i/o terapeutes'
            source='educatorId'
            reference='users'
            link={false}
          >
            <FunctionField
              /* render={(record) => `${record.firstname} ${record.lastname}`} */
              render={(record) => `${record.username}`}
            />
          </ReferenceField>

          <EaiaEquipEditButton
            title='Edita Equip'
            resource='intervention-team'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina Equip'
            resource='intervention-team'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
