import React, { useState, useCallback } from 'react';
import { Button, useCreate, useNotify, FormWithRedirect } from 'react-admin';

import { EaiaVincleForm } from './EaiaVincleForm';

import IconContentAdd from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const EaiaVincleCreateButton = ({ onChange, record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create] = useCreate('eaia-bond');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleCloseClickCb = useCallback(() => setShowDialog(false), []);

  const handleSubmit = async (values) => {
    const processed = {};
    processed.eaiumId = record.id;
    processed.eaium2Id = values.eaia;

    await create(
      { payload: { data: processed } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onChange();
          notify('ra.notification.created', {
            messageArgs: { smart_count: 1 },
          });
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );

    /*  await create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onChange();
          notify('ra.notification.created', {
            messageArgs: { smart_count: 1 },
          });
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    ); */
  };

  return (
    <>
      <Button onClick={handleClick} label='Afegir'>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Afegeix Germà/na'
      >
        <DialogTitle>Germà/na</DialogTitle>

        <FormWithRedirect
          resource='eaia-bond'
          save={handleSubmit}
          render={(props) => (
            <EaiaVincleForm
              {...props}
              eaia={record}
              handleCloseClick={handleCloseClickCb}
            />
          )}
        />
      </Dialog>
    </>
  );
};
