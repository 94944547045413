import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import {
  FormWithRedirect,
  SaveButton,
  useTranslate,
  Toolbar,
} from 'react-admin';

import { EaiaCustomDeleteButton } from './EaiaCustomDelete';

import { EaiaGeneral } from './general/EaiaGeneral';
import { EaiaCas } from './cas/EaiaCas';
import { EaiaAdmin } from './admin/EaiaAdmin';
import { EaiaEquip } from './equip/EaiaEquip';
import { EaiaSife } from './sife/EaiaSife';
import { EaiaTerapia } from './terapia/EaiaTerapia';
import { EaiaClosure } from './closure/EaiaClosure';
import { EaiaReport } from './report/EaiaReport';
//import { EaiaVincle } from './vincle/EaiaVincle';

// TODO: Afegir paddings per mobile

export const EaiaForm = (props, { record }) => {
  useEffect(() => {
    // Render parent component state
    if (props.formType === 'edit')
      props.setTitle(`${props.record.eaia_name} ${props.record.eaia_surname}`);

  }, [props.record]);

  const translate = useTranslate();
  const deleteConfirm = translate('ra.message.bulk_delete_files', {
    name: `${props.record.eaia_name} ${props.record.eaia_surname}`,
    smart_count: 1,
  });

  const [warn, setWarn] = useState(true);

  const handleWarn = useCallback(() => setWarn(!warn), [warn]);

  return (
    <FormWithRedirect
      {...props}
      warnWhenUnsavedChanges={warn}
      redirect={props.formType === 'edit' ? false : 'edit'}
      render={(formProps) => {
        return (
          <form>

            <EaiaGeneral {...props} />
            {props.formType === 'edit' && <EaiaCas {...props} />}
            {props.formType === 'edit' && <EaiaAdmin {...props} />}
            {props.formType === 'edit' && <EaiaEquip {...props} />}
            {props.formType === 'edit' && <EaiaSife {...props} />}
            {props.formType === 'edit' && <EaiaTerapia {...props} />}
            {props.formType === 'edit' && <EaiaClosure {...props} />}
            {props.formType === 'edit' && <EaiaReport {...props} />}
            {/* props.formType === 'edit' && <EaiaVincle {...props} />*/}
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <SaveButton
                disabled={formProps.pristine || formProps.submitting}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              {props.formType === 'edit' && (
                <EaiaCustomDeleteButton
                  record={formProps.record}
                  handleWarn={handleWarn}
                  confirmTitle={deleteConfirm}
                  label='Eliminar'
                />
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};
