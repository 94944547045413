import * as React from 'react';
import {
  Create,
  useCreateController,
  CreateContextProvider,
} from 'react-admin';

import { UserForm } from './UserForm';
import { FormToptoolbar } from '../common/MyTopToolbar';

export const UserCreate = (props) => {
  const controllerProps = useCreateController(props);
  return (
    <CreateContextProvider value={controllerProps}>
      <Create {...props} actions={<FormToptoolbar />}>
        <UserForm
          formType='create'
          formTitle='CREA UN NOU PERFIL DE TÈCNIC/A'
        />
      </Create>
    </CreateContextProvider>
  );
};
