import { TextInput, SelectInput, ReferenceInput } from 'react-admin';

export const userFilters = [
  <TextInput label='Nom' source='firstname' />,
  <TextInput label='Cognoms' source='lastname' />,
  <ReferenceInput
    source={'roleId'}
    reference={'roles'}
    label='Rol'
    sort={{ field: 'id', order: 'ASC' }}
  >
    <SelectInput optionText={'role'} style={{ width: '100%' }} />
  </ReferenceInput>,
  <TextInput label='Email' source='email' />,
];
