import React, { useState, useCallback } from 'react';
import { Button, useCreate, useNotify, FormWithRedirect } from 'react-admin';

import { EaiaSifeForm } from './EaiaSifeForm';

import IconContentAdd from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const EaiaSifeCreateButton = ({ onChange, record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create] = useCreate('extended-family');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleCloseClickCb = useCallback(() => setShowDialog(false), []);

  const handleSubmit = async (values) => {
    values.eaiumId = record.id;
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onChange();
          notify('ra.notification.created', {
            messageArgs: { smart_count: 1 },
          });
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label='Afegir'>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Edita Família'
      >
        <DialogTitle>Edita Família</DialogTitle>

        <FormWithRedirect
          resource='extended-family'
          save={handleSubmit}
          render={(props) => {
            return (
              <EaiaSifeForm {...props} handleCloseClick={handleCloseClickCb} />
            );
          }}
        />
      </Dialog>
    </>
  );
};
