import React, { useState, useCallback } from 'react';
import {
  Button,
  useUpdate,
  useNotify,
  FormWithRedirect,
  sanitizeEmptyValues,
} from 'react-admin';

import { EaiaAdminForm } from './EaiaAdminForm';

import IconContentEdit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const EaiaAdminEditButton = ({ record, resource, title, children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update] = useUpdate();

  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleCloseClickCb = useCallback(() => setShowDialog(false), []);
  const handleSubmit = async (values, initialValues) => {
    // Sanitize empty fields to set empty value insted of not updating the field (react-final-form logic)
    const newValues = sanitizeEmptyValues(initialValues, values);
    update(resource, record.id, newValues, record, {
      onSuccess: ({ data }) => {
        setShowDialog(false);
        // Update the comment form to target the newly created post
        // Updating the ReferenceInput value will force it to reload the available posts
        notify('ra.notification.updated', {
          messageArgs: { smart_count: 1 },
        });

      },
      onFailure: ({ error }) => {
        notify(error.message, 'error');
      },
    });
  };

  return (
    <>
      <Button onClick={handleClick}>
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>

        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          sanitizeEmptyValues={true}
          render={(props) => (
            <EaiaAdminForm
              {...props}
              record={record}
              handleCloseClick={handleCloseClickCb}
              formType='edit'
            />
          )}
        />
      </Dialog>
    </>
  );
};
