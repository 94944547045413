import { TextInput, ReferenceInput, AutocompleteInput, SelectInput, SearchInput } from 'react-admin';

export const eaiaFilters = [
  <TextInput label='Nom' source='eaia_name' />,
  <TextInput label='Cognoms' source='eaia_surname' />,

  <SelectInput
    choices={[
      { id: 1, label: 'Oberts' },
      { id: 0, label: 'Tancats' },
    ]}
    optionValue='id'
    optionText='label'
    source='eaia_active'
    label='Estat'

  />,
  <ReferenceInput
    source={'cityId'}
    reference={'cities'}
    label='Municipi'
    sort={{ field: 'id', order: 'ASC' }}
    filterToQuery={(searchText) => ({
      'city[$like]': `%${searchText}%`,
    })}
  >
    <AutocompleteInput optionText={'city'} style={{ width: '100%' }} />
  </ReferenceInput>,
  <TextInput label='SINIA' source='eaia_sinia' />

];



