import React from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,

} from 'react-admin';

import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaClosureForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>

            <DateInput
              label='Data'
              source='date'
              initialValue={formType === 'edit' ? record.date : null}
              validate={required()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ReferenceInput
              label='Motiu tancament'
              source='closure_reasonId'
              reference='case-closure'
              initialValue={
                formType === 'edit' ? record.closure_reasonId : null
              }
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
            >
              <SelectInput optionText={'closure_reason'} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextInput
              multiline
              source='observations'
              initialValue={formType === 'edit' ? record.observations : null}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={async () =>
            await handleSubmitWithRedirect(initialValues, values)
          }
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
