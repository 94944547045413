import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, usePermissions, Menu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';

import SubMenu from './SubMenu';

export const MyMenu = ({ ...props }) => {
  const [state, setState] = useState({
    menuEaia: true,
    menuConfig: true,
  });
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const classes = useStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const { permissions } = usePermissions();

  return (
    <Menu
      {...props}
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <SubMenu
        handleToggle={() => handleToggle('menuEaia')}
        isOpen={state.menuEaia}
        name='EAIA'
        icon={<PersonIcon />}
      >
        <MenuItemLink
          to={{
            pathname: '/eaia',
            state: { _scrollToTop: true },
          }}
          primaryText={`Expedients`}
          leftIcon={<LibraryBooks />}
        />
        {/*<MenuItemLink
          to={{
            pathname: '/foo',
            state: { _scrollToTop: true },
          }}
          primaryText='Foo'
          leftIcon={<SupervisorAccountIcon />}
        />*/}
        {/*<MenuItemLink
          to={{
            pathname: '/informes',
            state: { _scrollToTop: true },
          }}
          primaryText='Informes'
          leftIcon={<SupervisorAccountIcon />}
        />*/}
        {<MenuItemLink
          to={{
            pathname: '/informesfiltres',
            state: { _scrollToTop: true },
          }}
          primaryText='Informes'
          leftIcon={<SupervisorAccountIcon />}
        />}
      </SubMenu>
      {permissions === 'admin' && (
        <MenuItemLink
          to={{
            pathname: '/users',
            state: { _scrollToTop: true },
          }}
          primaryText='Tècnics/ques'
          leftIcon={<SupervisorAccountIcon />}
        />
      )}
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));
