import React, { useCallback } from 'react';
import {
  required,
  number,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';



/**** d QUITQ ESTO */
import { useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const EaiaAdminForm = ({
  record,
  handleSubmitWithRedirect,
  handleCloseClick,
  loading,
  pristine,
  submitting,
  saving,
  formType,
}) => {
  const { values, initialValues } = useFormState();

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>



            <ReferenceInput
              label='Fase intervenció'
              source='phaseId'
              reference='intervention-phase'
              initialValue={formType === 'edit' ? record.phaseId : null}
              perPage={100}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
              filterToQuery={(searchText) => ({
                'name[$like]': `%${searchText}%`,
              })}
            >
              <AutocompleteInput optionText={'name'} fullWidth />
            </ReferenceInput>
          </Grid>

        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data inici'
              source='start_date'
              initialValue={formType === 'edit' ? record.start_date : null}
              validate={required()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DateInput
              label='Data fi'
              source='end_date'
              initialValue={formType === 'edit' ? record.end_date : null}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <TextInput
              source='sinia'
              label='Expedient Sinia'
              initialValue={formType === 'edit' ? record.sinia : null}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label='Data Resolució'
              source='resolution_date'
              initialValue={formType === 'edit' ? record.resolution_date : null}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>


            <ReferenceInput
              label='Mesura'
              source='measureId'
              reference='protection-measure'
              initialValue={formType === 'edit' ? record.measureId : null}
              perPage={100}
              sort={{ field: 'measure', order: 'ASC' }}
              filterToQuery={(searchText) => ({
                'measure[$like]': `%${searchText}%`,
              })}
            >
              <AutocompleteInput resettable
                optionText={'measure'} fullWidth />

            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='cose_observations'
              label='Observacions'
              initialValue={formType === 'edit' ? record.cose_observations : null}
              fullWidth
            />
          </Grid>
        </Grid>
        {values.measureId === 9 && (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  style={{
                    margin: '20px 0',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  Compromís socioeducatiu
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <DateInput
                  source='cose_document_date'
                  label='Data document'
                  initialValue={
                    formType === 'edit' ? record.cose_document_date : null
                  }
                  validate={required()}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateInput
                  source='cose_closure_date'
                  label='Data tancament'
                  initialValue={
                    formType === 'edit' ? record.cose_closure_date : null
                  }
                  /*validate={required()}*/
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <TextInput
                  source='cose_extension'
                  label='Num. Pròrroga'
                  initialValue={
                    formType === 'edit' ? record.cose_extension : null
                  }

                  validate={[number(), required()]}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  choices={[
                    { id: 1, label: 'Sí' },
                    { id: 0, label: 'No' },
                  ]}
                  optionValue='id'
                  optionText='label'
                  source='cose_benefit'
                  label='Prestació econòmica'
                  initialValue={
                    formType === 'edit' ? record.cose_benefit : null
                  }
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {values.cose_closure_date !== null && (
                <>
                  <Grid item xs={12} md={6}>
                    <ReferenceInput
                      label='Motiu del tancament'
                      source='cose_closureId'
                      reference='cose-closure'
                      initialValue={
                        formType === 'edit' ? record.cose_closureId : null
                      }
                      validate={required()}
                      perPage={100}
                      sort={{ field: 'closure_reason', order: 'ASC' }}
                    >
                      <SelectInput optionText={'closure_reason'} fullWidth />
                    </ReferenceInput>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          label='ra.action.cancel'
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={useCallback(
            () => handleSubmitWithRedirect(initialValues, values),
            [values, initialValues, handleSubmitWithRedirect]
          )}
          disabled={pristine || submitting || loading}
          saving={saving}
        />
      </DialogActions>
    </>
  );
};
