
//https://blog.openreplay.com/display-data-with-material-ui-s-data-grid/

// in src/Foo.js
import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// in src/users.js

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SimpleList,
  Title, Filter, // Import Filter component
  TextInput, // Import TextInput for text-based filtering
} from 'react-admin';


import { useMediaQuery } from '@material-ui/core';

// TODO: CUSTOM SORTBY BUTTON TO BE ABLE TO SORT BY ASSOCIATED ENTITY PROPERTY (ROLE & SPECIALIST)
const FooFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label='Buscar por nombre'
      source='eaia_name'
      resettable
      alwaysOn

    />
    <TextInput label='Buscar por apellidos' source='eaia_surname' resettable alwaysOn />
    {/* Add more TextInput components for other columns */}
  </Filter>
);
const Foo = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return (
    <List
      basePath={'eaia'}
      resource='eaia'
      bulkActionButtons={false}

      filters={<FooFilter />} // Use the custom filter component
      style={{ overflow: 'auto' }}
      title=' '
    >
      <Card>
        <Title title='INFORMES' />
        <CardContent>
          {isDesktop ? (
            <Datagrid rowClick={(id, basePath, record) => `/foo/informe/${id}`}>
              <TextField source='eaia_name' label='Nom' />
              <TextField source='eaia_surname' label='Cognoms' />
              <ReferenceField
                source='cityId'
                reference='cities'
                label='Ciutat'
                link={false}
              >
                <TextField source='city' />
              </ReferenceField>
              <ReferenceField
                source='genreId'
                reference='genre'
                label='Sexe'
                link={false}
              >
                <TextField source='genre' />
              </ReferenceField>
            </Datagrid>
          ) : (
            <SimpleList
              primaryText={(record) =>
                `${record.eaia_name} ${record.eaia_lastname}`
              }
              secondaryText={(record) => (
                <ReferenceField
                  record={record}
                  source='cityId'
                  reference='cities'
                  link={false}
                >
                  <TextField source='cities' />
                </ReferenceField>
              )}
            /* tertiaryText={(record) => (
          <ReferenceField
            record={record}
            source='roleId'
            reference='roles'
            sortBy='role'
            link={false}
          >
            <TextField source='role' />
          </ReferenceField>
        )} */
            />
          )}
        </CardContent>
      </Card>
    </List>
  );
};

export default Foo;
