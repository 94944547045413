import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaAdminCreateButton } from './EaiaAdminCreate';
import { EaiaAdminEditButton } from './EaiaAdminEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';

const useStyles = makeStyles({
  expanded: {
    boxShadow: 'gray 0px 2px 0px 2px',
  },
});

const CosePanel = ({ id, record, resource }) => {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        marginTop: '10px',
      }}
    >
      <Grid item xs={12} md={1}>
        <Typography sx={{ fontWeight: 600, color: 'rgb(75, 75, 75);' }}>
          COSE
        </Typography>
      </Grid>
      <Grid item xs={12} md={11}>
        <ReferenceManyField reference='administrative-status' target='id'>
          <Datagrid>
            <DateField
              source='cose_document_date'
              label='Data document'
              locales='es-ES'
              sortable={false}
            />
            <DateField
              source='cose_closure_date'
              label='Data tancament'
              locales='es-ES'
              sortable={false}
            />
            <TextField
              source='cose_extension'
              label='Núm. Pròrroga'
              sortable={false}
            />
            <FunctionField
              label='Prestació economica'
              sortable={false}
              render={(record) =>
                record.cose_benefit === parseInt('1', 10) ? (
                  <Typography variant='body'>Sí</Typography>
                ) : (
                  <Typography variant='body'>No</Typography>
                )
              }
            />
            <ReferenceField
              label='Motiu del tancament'
              source='cose_closureId'
              reference='cose-closure'
              link={false}
              sortable={false}
            >
              <TextField source='closure_reason' />
            </ReferenceField>
            <Typography />
            <Typography />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Grid>
  );
};

export const EaiaAdminList = (props) => {
  const [version, setVersion] = useState(0);
  const classes = useStyles();
  const dataclass = datagridStyle();


  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaAdminCreateButton
            record={props.record}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='administrative-status'
        target='eaiumId'
        perPage={25}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid
          isRowExpandable={(row) => {
            return row.measureId === 9;
          }}
          expand={<CosePanel />}
          classes={{ expandedPanel: classes.expanded }}
          className={dataclass.buttons}
        >
          <TextField source='id' label='#' />
          <ReferenceField
            label='Fase intervenció'
            source='phaseId'
            reference='intervention-phase'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <DateField source='start_date' label='Data inici' locales='es-ES' />
          <DateField source='end_date' label='Data final' locales='es-ES' />
          <TextField source='sinia' label='Expedient Sinia' />
          <DateField
            source='resolution_date'
            label='Data resolució'
            locales='es-ES'
          />

          <ReferenceField
            label='Mesura'
            source='measureId'
            reference='protection-measure'
            link={false}
          >
            <TextField source='measure' />
          </ReferenceField>
          <TextField source='cose_observations' label='Observacions' />


          <EaiaAdminEditButton
            title='Edita situació administrativa'
            resource='administrative-status'
          />

          <EaiaDeleteButton

            onChange={handleChange}

            title='Elimina situació administrativa'
            resource='administrative-status'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
