import feathers from '@feathersjs/client';

const host = 'https://dev.eaia.anoia.cat';
// Connect to a different URL
const restClient = feathers.rest(host);

export default feathers()
  // Configure an AJAX library (see below) with that client
  .configure(restClient.fetch(window.fetch.bind(window)))
  .configure(
    feathers.authentication({
      jwtStrategy: 'jwt',
      storage: window.localStorage,
    })
  );
