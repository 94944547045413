import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";

function CustomToolbar({ filterByHombre, filterByEstat, handleFilterByHombre, handleFilterByEstat }) {
  return (
    <GridToolbarContainer>
      <GridToolbar />
      <Button
        variant="contained"
        color={filterByEstat ? "primary" : "default"}
        onClick={handleFilterByEstat}
      >
        Casos oberts
      </Button>
    </GridToolbarContainer>
  );
}

function App() {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  //const [filterByHombre, setFilterByHombre] = useState(false);
  const [filterByEstat, setFilterByEstat] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = async () => {
    try {
      // Primero, obtén las EAIAs
      const eaiasResponse = await axios.get("https://dev.eaia.anoia.cat/eaia");
      const eaias = eaiasResponse.data.data;


      // Ahora, obtén los estados administrativos
      const administrativeStatusResponse = await axios.get("https://dev.eaia.anoia.cat/administrative-status");
      const administrativeStatus = administrativeStatusResponse.data;

      // Ahora, obtén las fases de intervención 
      const interventionPhaseResponse = await axios.get("https://dev.eaia.anoia.cat/intervention-phase");
      const interventionPhases = interventionPhaseResponse.data.data;
      // Ahora, obtén las medidas 
      const protectionMeasureResponse = await axios.get("https://dev.eaia.anoia.cat/protection-measure");
      const protectionMeasures = protectionMeasureResponse.data.data;

      // Ahora, obtén los equipos de interveción
      const interventionTeamResponse = await axios.get("https://dev.eaia.anoia.cat/protection-measure");
      const interventionTeams = interventionTeamResponse.data.data;

      const eaiasWithData = eaias.map((eaia) => {

        const eaiaAdministratives = administrativeStatus.filter((administrative) => administrative.eaiumId === eaia.id);


        const eaiaFases = eaiaAdministratives.map((administrative) => {
          const fase = interventionPhases.find((f) => f.id === administrative.phaseId);
          return fase ? fase.name : "No establert";
        });
        /*** LISTA MESURAS */
        const eaiaMesures = eaiaAdministratives.map((administrative) => {
          const mesura = protectionMeasures.find((m) => m.id === administrative.measureId);
          return mesura ? mesura.measure : "No establert";
        });

        // Ordenamos los administrativos por id de mayor a menor y tomamos el más reciente (el primero)
        const sortedAdministratives = eaiaAdministratives.sort((a, b) => b.id - a.id);
        const latestAdministrative = sortedAdministratives[0];

        // Obtenemos la fase correspondiente al administrativo más reciente (si existe)
        const fase = latestAdministrative ? interventionPhases.find((f) => f.id === latestAdministrative.phaseId) : null;
        const faseName = fase ? fase.name : "No establert";

        // Obtenemos la mesura correspondiente al administrativo más reciente (si existe)
        const mesura = latestAdministrative ? protectionMeasures.find((m) => m.id === latestAdministrative.measureId) : null;
        const mesuraName = mesura ? mesura.measure : "No establert";

        console.log("latestAdministrative:", latestAdministrative);
        console.log("mesures", mesura);
        /**** RETURN */
        return {
          ...eaia,
          faseIntervencio: faseName,
          latestMesura: mesuraName,
          todaslasfases: eaiaFases,
          todaslasmesuras: eaiaMesures,
        };
      });

      setRows(eaiasWithData);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = rows.filter((eaia) => {
      //const genreFilter = !filterByHombre || eaia.genres.some((genre) => genre.genre === "Home");
      const estatFilter = !filterByEstat || eaia.eaia_active === 1;
      //return genreFilter && estatFilter;
      return estatFilter;
    });

    setFilteredRows(filteredData);
  }, [rows, filterByEstat, rowsPerPage]);

  /*const handleFilterByHombre = () => {
    setFilterByHombre(!filterByHombre);
  };*/

  const handleFilterByEstat = () => {
    setFilterByEstat(!filterByEstat);
  };

  const columns = [
    //  { field: "id", headerName: "ID", width: 70, flex: 1 },
    { field: "eaia_sinia", headerName: "SINIA", width: 70, flex: 1 },
    { field: "eaia_name", headerName: "Nombre", width: 120, flex: 1 },
    { field: "eaia_surname", headerName: "Apellido", width: 120, flex: 1 },
    { field: "eaia_birthdate", headerName: "Data Naixement", width: 120, flex: 1 },
    {
      field: "faseIntervencio",
      headerName: "Darrera Fase de intervenció",
      width: 120,
      flex: 1,
      valueGetter: (params) => {
        const faseIntervencio = params.value;
        if (faseIntervencio) {
          return faseIntervencio;
        } else {
          return "No hem trobat res";
        }
      },
    },
    {
      field: "latestMesura",
      headerName: "Mesura",
      width: 120,
      flex: 1,
      valueGetter: (params) => {
        const latestMesura = params.value;
        if (latestMesura) {
          return latestMesura;
        } else {
          return "No hem trobat res";
        }
      },
    },
    {
      field: "todaslasfases",
      headerName: "Fases fdsafs",
      width: 120,
      flex: 1,
      valueGetter: (params) => {
        const faseIntervencio = params.value;
        const faseIntervencioNames =
          faseIntervencio && faseIntervencio.length > 0
            ? faseIntervencio.join(", ")
            : "No establert";
        return faseIntervencioNames;
      },
      filterable: true,
      resizable: false,
      renderCell: (params) => {


        return (
          <div style={{
            display: "flex", flexWrap: "wrap",
            overflow: "auto"
          }}>
            <span>{params.value}</span>
            <span>hola</span>
          </div>
        );


      },
    },
    {
      field: "todaslasmesures",
      headerName: "Mesures",
      width: 120,
      flex: 1,
      valueGetter: (params) => {
        const faseIntervencio = params.value;
        const faseIntervencioNames =
          faseIntervencio && faseIntervencio.length > 0
            ? faseIntervencio.join(", ")
            : "No establert";
        return faseIntervencioNames;
      },
      filterable: true,
      resizable: false,
      /* renderCell: (params) => (
         <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
       ),*/

    },
  ];

  return (
    <div style={{ height: "calc(100vh - 120px)", width: "100%" }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        sorting
        components={{
          Toolbar: () => (
            <CustomToolbar
              filterByEstat={filterByEstat}
              handleFilterByEstat={handleFilterByEstat}
            />
          ),
        }}

        pageSize={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50]}
        checkboxSelection
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        autoHeight
      />
    </div>
  );
}

export default App;
