import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  SingleFieldList,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaVincleCreateButton } from './EaiaVincleCreate';

export const EaiaVincleList = (props) => {
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ textDecoration: 'underline' }}>
            Germans/nes
          </Typography>

          <EaiaVincleCreateButton
            record={props.record}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Germans/nes'
        /*    source='id' */
        target='eaiumId'
        reference='eaia-bond'
        key={version}
      /* style={{ width: 300 }}*/
      >
        <Datagrid>
          <ReferenceManyField
            source='eaium2Id'
            target='id'
            reference='eaia'
            label='Nom i cognoms '
          >
            <SingleFieldList style={{ padding: '6px 24px 6px 16px' }}>
              <>
                <TextField source='eaia_name' />
                <TextField source='eaia_surname' style={{ marginLeft: 5 }} />
              </>
            </SingleFieldList>
          </ReferenceManyField>
          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina Germans/nes'
            resource='eaia-bond'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
