//https://material-table.com/

/** IMPORTANTE PARA FILTRAR POR FECHAS: https://github.com/mbrn/material-table/issues/333 */
import React, { useEffect, useState } from "react";
//import axios from "axios";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@material-ui/data-grid";
//import { createTheme } from "@material-ui/core/styles";
//import { ThemeProvider } from "@material-ui/styles";
import { ThemeProvider, createTheme } from '@mui/material';
//import FilterNumericBetween from './datafilter.js';
import restClient from './client/feathersClient'; // Importa el cliente Feathers.js

function CustomToolbar({ filterByHombre, filterByEstat, handleFilterByHombre, handleFilterByEstat }) {
  return (
    <GridToolbarContainer>

      <Button
        variant="contained"
        color={filterByEstat ? "primary" : "default"}
        onClick={handleFilterByEstat}
      >
        Casos oberts
      </Button>
    </GridToolbarContainer>
  );
}

function App() {

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterByEstat, setFilterByEstat] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fetchData = async () => {
    try {
      /*  // Primero, obtén las EAIAs
        const eaiasResponse = await axios.get("https://dev.eaia.anoia.cat/eaia");
        const eaias = eaiasResponse.data.data;
      
    
  
        // Ahora, obtén los estados administrativos
        const administrativeStatusResponse = await axios.get("https://dev.eaia.anoia.cat/administrative-status");
        const administrativeStatus = administrativeStatusResponse.data;
  
        // Ahora, obtén las fases de intervención 
        const interventionPhaseResponse = await axios.get("https://dev.eaia.anoia.cat/intervention-phase");
        const interventionPhases = interventionPhaseResponse.data.data;
        // Ahora, obtén las medidas 
        const protectionMeasureResponse = await axios.get("https://dev.eaia.anoia.cat/protection-measure");
        const protectionMeasures = protectionMeasureResponse.data.data;
  
        // Ahora, obtén los equipos de interveción
        const interventionTeamResponse = await axios.get("https://dev.eaia.anoia.cat/intervention-team");
        const interventionTeams = interventionTeamResponse.data.data;
  */
      const eaiasResponse = await restClient.service('eaia').find();
      const eaias = eaiasResponse.data;

      const administrativeStatus = await restClient.service('administrative-status').find();


      const interventionPhasesResponse = await restClient.service('intervention-phase').find();
      const interventionPhases = interventionPhasesResponse.data;
      const protectionMeasuresResponse = await restClient.service('protection-measure').find();
      const protectionMeasures = protectionMeasuresResponse.data;
      const interventionTeamsResponse = await restClient.service('intervention-team').find();
      const interventionTeams = interventionTeamsResponse.data;

      const eaiasWithRows = [];

      eaias.forEach((eaia) => {

        const eaiaAdministratives = administrativeStatus.filter(
          (administrative) => administrative.eaiumId === eaia.id
        );
        if (eaiaAdministratives.length === 0) {
          eaiasWithRows.push({
            ...eaia,
          });
        } else {
          eaiaAdministratives.forEach((administrative) => {
            const fase = interventionPhases.find((f) => f.id === administrative.phaseId);
            const faseName = fase ? fase.name : "";

            const mesura = protectionMeasures.find((m) => m.id === administrative.measureId);
            const mesuraName = mesura ? mesura.measure : "";

            const adminTeams = interventionTeams.filter(
              (team) => team.admin_statusId === administrative.id
            );
            if (adminTeams.length === 0) {
              eaiasWithRows.push({
                ...eaia,

                fase: faseName, // Add a separate entry for each phase
                mesura: mesuraName, // Add a separate entry for each mesura
                inifase: administrative.start_date,
                endfase: administrative.end_date,

              });
            } else {

              adminTeams.forEach((team) => {

                eaiasWithRows.push({
                  ...eaia,

                  fase: faseName, // Add a separate entry for each phase
                  mesura: mesuraName, // Add a separate entry for each mesura
                  inifase: administrative.start_date,
                  endfase: administrative.end_date,
                  tecnic1: team.tecnic1Id,
                });

              });


            }
          });
        }
      });


      setRows(eaiasWithRows);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    const filteredData = rows.filter((eaia) => {
      const estatFilter = !filterByEstat || eaia.eaia_active === 1;
      return estatFilter;
    });

    setFilteredRows(filteredData);
  }, [rows, filterByEstat, rowsPerPage]);



  /*const handleFilterByHombre = () => {
    setFilterByHombre(!filterByHombre);
  };*/

  const handleFilterByEstat = () => {
    setFilterByEstat(!filterByEstat);
  };

  const columns = [


    { field: "id", title: "EAIA ID", width: 70, flex: 1 },
    { field: "eaia_name", title: "Nom", width: 70, flex: 1 },
    { field: "eaia_surname", title: "Cognom", width: 70, flex: 1 },
    { field: "eaia_birthdate", title: "D.N.", width: 70, flex: 1 },
    { field: "fase", title: "Fase", width: 150, flex: 1 },
    { field: "mesura", title: "Mesura", width: 150, flex: 1 },
    { field: "inifase", title: "Inici de fase", width: 150, flex: 1 },
    { field: "endfase", title: "Fi de fase", width: 150, flex: 1 },
    { field: "tecnic1", title: "Tècnic", width: 150, flex: 1 },
  ];

  // Create a theme instance
  const theme = createTheme();

  return (
    <div style={{ height: "calc(100vh - 120px)", width: "100%" }}>
      <ThemeProvider theme={theme}>

        <MaterialTable
          title="Informes"
          columns={columns}
          data={filteredRows}
          options={{
            pageSize: rowsPerPage,
            pageSizeOptions: [5, 10, 20, 50],
            sorting: true,
            selection: true,
            search: true,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            grouping: true
          }}

        // other props
        /*  components={{
            Toolbar: props => (
              <div style={{ backgroundColor: '#e8eaf5' }}>
                <MTableToolbar {...props} />
              </div>
            )
          }}*/
        /*  components={{
            Toolbar: (props) => (
              <CustomToolbar
                filterByEstat={filterByEstat}
                handleFilterByEstat={handleFilterByEstat}
                {...props}
              />
            ),
          }}*/
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
