import * as React from 'react';
import {
  Create,
  useCreateController,
  CreateContextProvider,
} from 'react-admin';

import { EaiaForm } from './EaiaForm';
import { FormToptoolbar } from '../common/MyTopToolbar';

export const EaiaCreate = (props) => {
  const controllerProps = useCreateController(props);
  return (
    <CreateContextProvider value={controllerProps}>
      <Create
        {...props}
        actions={<FormToptoolbar />}
        title={`Creació d'expedient`}
      >
        <EaiaForm formType='create' />
      </Create>
    </CreateContextProvider>
  );
};
