import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { datagridStyle } from '../../layout/DatagridStyle';

import { EaiaReportEditButton } from './EaiaReportEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaReportCreateButton } from './EaiaReportCreate';

export const EaiaReportList = (props) => {
  const classes = datagridStyle();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <EaiaReportCreateButton record={props.record} onChange={handleChange} />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='reports-made'
        target='eaiumId'
        perPage={5}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid className={classes.buttons}>
          <DateField
            source='data'
            label="Data"
            locales='es-ES'
          />

          <ReferenceField
            label="Tipus d'informe"
            source='rtypeId'
            reference='report-type'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>


          <EaiaReportEditButton
            title='Edita informe'
            resource='reports-made'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina informe'
            resource='reports-made'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
