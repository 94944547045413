import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceManyField,
  DateField,
  ReferenceField,
  Datagrid,
  Pagination,
  FunctionField,
  BooleanInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { datagridStyle } from '../../layout/DatagridStyle';
import { makeStyles } from '@material-ui/core/styles';

import { EaiaClosureEditButton } from './EaiaClosureEdit.js';
import { EaiaDeleteButton } from '../common/EaiaDelete.js';
import { EaiaClosureCreateButton } from './EaiaClosureCreate';

const useStyles = makeStyles({
  expanded: {
    boxShadow: 'gray 0px 2px 0px 2px',
  },
  title: { textDecoration: '', padding: '10px 0 0 20px' },
  observations: { padding: '20px', fontSize: 14, whiteSpace: 'pre-wrap' },
});

/*const Observations = ({ id, record, resource }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant='h6' className={classes.title}>
        Observacions
      </Typography>
      <Typography className={classes.observations}>
        {record.observations}
      </Typography>
    </>
  );
};*/

export const EaiaClosureList = (props) => {
  const classes = datagridStyle();
  const expandClass = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          justifyContent: 'space-between',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <Grid item xs={12}>
          <BooleanInput label="Cas tancat/obert" source="eaia_active" />

        </Grid>

        <Grid item xs={12}>

          <EaiaClosureCreateButton
            record={props.record}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <ReferenceManyField
        reference='eaia-closure'
        target='eaiumId'
        perPage={5}
        pagination={<Pagination />}
        key={version}
      >
        <Datagrid
        /* isRowExpandable={(row) => {
           return row.observations;
         }}
         className={classes.buttons}
         classes={{ expandedPanel: expandClass.expanded }}
         expand={<Observations />}*/
        >
          <DateField source='date' label='Data' locales='es-ES' />

          <ReferenceField
            label='Motiu tancament'
            source='closure_reasonId'
            reference='case-closure'
            link={false}
          >

            <TextField source='closure_reason' />
          </ReferenceField>
          <TextField source='observations' label='Observacions' />
          <FunctionField
            sortable={false}
            render={(record) => <div style={{ minWidth: 200 }}></div>}
          />

          <EaiaClosureEditButton
            title='Edita tancament del cas'
            resource='eaia-closure'
          />

          <EaiaDeleteButton
            onChange={handleChange}
            title='Elimina tancament del cas'
            resource='eaia-closure'
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
